/* eslint-disable import/named */
import axios, { Cancel, CancelToken } from 'axios';
import { APP_DOMAIN, BACKEND_URL } from 'services/constants';
import Cookie from 'services/Cookie';
import to from 'util-to';

const instance = axios.create({
  baseURL: `${BACKEND_URL}/api`,
  timeout: 180000,
});

const instanceS3 = axios.create({
  timeout: 20000,
});

const http = {
  handleError(err, url) {
    if (err) {
      if (err.response) {
        if (err.request.responseURL === `${BACKEND_URL}/api/v1/token`) {
          throw err.response;
        }
        if (err.response.status === 401 && !url.startsWith('/v1/token')) {
          window.location.replace(`https://${APP_DOMAIN}/logout`);
          return;
        }
        throw err.response;
      } else if (err instanceof Cancel) {
        throw new Error('Canceled');
      } else {
        throw err;
      }
    }
  },
  authorize(inToken) {
    const token = inToken || Cookie.get('token');
    const company = Cookie.get('company');
    const isAuthorized = token && company;
    const impersonate = inToken ? null : Cookie.get('impersonate');

    if (!isAuthorized) return;

    const { headers } = instance.defaults;
    headers.common.Authorization = `Bearer ${token.hash}`;
    headers['x-company'] = company._id;
    if (impersonate) {
      headers['x-impersonate'] = impersonate;
    }
  },
  async request(method, url, params, config) {
    if (config && config.getCancelFunc) {
      // eslint-disable-next-line no-param-reassign
      config.cancelToken = new CancelToken(config.getCancelFunc);
    }
    this.authorize(config?.token);
    const [err, response] = await to(instance[method](url, params, config));
    this.handleError(err, url);

    return response.data;
  },
  async get(url, config) {
    return this.request('get', url, null, config);
  },
  async post(url, params, config) {
    return this.request('post', url, params, config);
  },
  async put(url, params, config) {
    return this.request('put', url, params, config);
  },
  async delete(url, params) {
    return this.request('delete', url, params);
  },
  async patch(url, params) {
    return this.request('patch', url, params);
  },
  async putS3(url, params, config) {
    if (config && config.getCancelFunc) {
      // eslint-disable-next-line no-param-reassign
      config.cancelToken = new CancelToken(config.getCancelFunc);
    }
    const [err, response] = await to(instanceS3.put(url, params, config));
    this.handleError(err);

    return response.data;
  },

  async getS3(url, params, config) {
    const [err, response] = await to(instanceS3.get(url, params, config));
    this.handleError(err);
    return {
      data: response.data,
      contentType: response.headers['content-type'],
      length: response.headers['content-length'],
    };
  },
};

export default http;
