/* eslint-disable class-methods-use-this */
import Cookie from 'services/Cookie';
import Http from 'services/Http';
import redirect from 'utils/redirect';

const sanitize = string => `${string.replace(/\D/g, '')}`;

class Auth {
  listSessions(userId) {
    return Http.get(`/v1/user/${userId}/tokens`);
  }

  deleteSessions(userId) {
    return Http.delete(`/v1/user/${userId}/tokens`);
  }

  deleteSession(userId, sessionId) {
    return Http.delete(`/v1/user/${userId}/token/${sessionId}`);
  }

  verify() {
    return Http.post('/v1/token/verify');
  }

  sendSMS(phone) {
    return Http.post('/v1/token/request', { phone: `+${sanitize(phone)}` });
  }

  sendEmail({ name, email, company }) {
    return Http.post('/v1/company/request', { userName: name, email, companyName: company });
  }

  async user(config) {
    const {
      data: { user },
    } = await Http.get('/v2/user/me', config);
    return user;
  }

  async logout() {
    const token = Cookie.get('token');

    if (token) {
      await Http.delete(`/v1/token/${token.hash}`).catch(() => {});

      Cookie.set('token', '');
    }

    if (window.location.pathname !== '/logout') {
      setTimeout(() => redirect('/logout'), 300);
    }
  }

  async acceptInvite(phone, token) {
    try {
      await Http.post('/v1/user/confirm/invite', {
        phone,
        token,
      });
    } catch (e) {
      // Ignore errors
    }
    window.location.replace('/');
  }
}

export default new Auth();
